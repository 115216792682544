import React, { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import useFetchData from './hooks/useFetchData';
import Graph from 'components/specific/Graph/Graph';
import { DeviceEvent } from 'interfaces/devices/DeviceEvents.interface';
import { getResolution, getUnit } from 'utils/graph.utils';
import { random } from 'lodash';
import Spinner from 'components/common/Spin/Spin';
import styles from './DeviceOverview.module.scss';
import { useNavigate } from 'react-router-dom';
import useUrlParams from '../../hooks/useUrlParams';
import { generateMockData } from 'utils/mockGraphsData';

type DeviceOverviewProps = {
  deviceId: string;
  graphTabs: Array<{
    label: string;
    key: string;
    graphParameter: string;
    graphLabel: string;
    icon: React.ReactNode;
    className: string;
  }>;
};
const from = dayjs().subtract(1, 'day').unix();
const to = dayjs().unix();

const DeviceOverview: React.FC<DeviceOverviewProps> = ({ deviceId, graphTabs }) => {
  const resolution = getResolution([from, to]);
  const [eventsList, loading, fetchData] = useFetchData(deviceId);
  const navigate = useNavigate();
  const [urlParams] = useUrlParams();

  const noDataForThisInterval = useMemo(
    () => eventsList.length === 0 && !loading,
    [eventsList, loading]
  );

  useEffect(() => {
    fetchData(from, to, resolution);
  }, [deviceId, fetchData, resolution]);

  const handleGraphClick = (tabKey: string) => {
    navigate(`/device?id=${deviceId}&tab=${tabKey}&timeOption=${urlParams.timeOption}`);
  };

  const mockData = generateMockData();

  const renderGraph = (
    currentTab: DeviceOverviewProps['graphTabs'][number],
    events: DeviceEvent[],
    showOverlay: boolean,
    isMock: boolean = false
  ) => {
    const lastValue = events[events.length - 1];
    const index = currentTab.graphParameter.replace(
      'parameters.',
      ''
    ) as keyof DeviceEvent['parameters'];

    const unit = getUnit(index as string);

    const tabData = events.reduce(
      (acc, event) => {
        const value = event.parameters[index];
        if (value !== undefined) {
          acc.push(value);
        }
        return acc;
      },
      [] as Array<number | null>
    );

    const showNoData = showOverlay || tabData.length === 0 || !resolution;

    const parameterValue = showNoData ? 'N/A' : lastValue?.parameters[index];
    const blurClass = showNoData ? styles.blurContent : '';

    const showLoading = loading || events.length === 0;

    return (
      currentTab.key !== '1' && (
        <div
          className={styles.overviewGraph}
          key={currentTab.key + random}
          onClick={() => handleGraphClick(currentTab.key)}
          style={{ cursor: showOverlay ? 'default' : 'pointer' }}
        >
          <Spinner spinning={showLoading}>
            <span className={`${styles.graphOverviewTitle} ${styles[currentTab.className]}`}>
              <div className={styles.graphOverviewIconContainer}>{currentTab.icon}</div>
              <div>
                {currentTab.graphLabel}:{' '}
                <div className={styles.graphOverviewValue}>
                  {parameterValue}
                  {!showNoData && <span className={styles.graphOverviewUnit}> {unit} </span>}
                </div>
              </div>
            </span>
            <div className={blurClass}>
              <div className={styles.graphOverviewParent}>
                <Graph
                  eventsList={showNoData ? mockData : eventsList}
                  parameter={currentTab.graphParameter}
                  parameterLabel={currentTab.graphLabel}
                  zoomDomain={null}
                  setZoomDomain={() => {}}
                  urlParams={{}}
                  ticks={[]}
                  isDot={true}
                  setIsDot={() => {}}
                  getTickFormatter={() => ''}
                  getBrushClass={() => ''}
                  hasBrush={false}
                  hasXAxis={false}
                  hasYAxis={false}
                  hasTooltip={false}
                  graphMargin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                  style={{ cursor: 'pointer', width: '100%' }}
                  disableWheel={true}
                  disableMouseEvents={true}
                  showPointRef={true}
                  graphLineStyle='basis'
                />
              </div>
              <div className={styles.graphOverviewFooter}>*Data from last 24 hours</div>
            </div>
          </Spinner>
          {showNoData && !showLoading && (
            <div className={styles.noDataOverlay}>
              <div className={styles.noDataMessage}>
                {isMock ? 'No data from last day' : 'No data available'}
              </div>
            </div>
          )}
        </div>
      )
    );
  };

  const renderOverview = () => {
    if (noDataForThisInterval) {
      return graphTabs.map((tab) => renderGraph(tab, mockData, true, true));
    }

    return graphTabs.map((tab) => (tab.key !== '1' ? renderGraph(tab, eventsList, false) : null));
  };

  return <div className={styles.overviewContent}>{renderOverview()}</div>;
};

export default DeviceOverview;
