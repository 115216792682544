import { useState, useCallback, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { debounce } from 'lodash';

export const useZoomAndPan = ({
  eventsList,
  activeZoomDomain,
  setZoomDomain,
  updateUrlParams,
  disableWheel,
  disableMouseEvents,
  urlParams,
  setIsDot,
}: any) => {
  const [isPanning, setIsPanning] = useState(false);
  const [startX, setStartX] = useState<number | null>(null);
  const isZoomingRef = useRef(false); // To prevent overlapping zoom state changes
  const isMouseOverChart = useRef(false); // Track if the mouse is over the chart
  const lineChartWrapperRef = useRef<HTMLDivElement | null>(null);
  const pendingZoomRange = useRef<[number, number] | null>(null); // Store pending zoom range

  // Create the debounced function outside useCallback
  const debouncedUpdateZoomDomain = useRef(
    debounce((newMin, newMax, maxZoom, setZoomDomain, updateUrlParams, urlParams, setIsDot) => {
      if (newMin + maxZoom < newMax) {
        setZoomDomain([newMin, newMax]);
        updateUrlParams({
          ...urlParams,
          zoomDomainMin: newMin,
          zoomDomainMax: newMax,
        });
        setIsDot(true);
      }
      isZoomingRef.current = false;
    }, 10)
  ).current;

  // Handle zooming via mouse wheel
  const handleWheel = useCallback(
    (event: any) => {
      if (disableWheel || !isMouseOverChart.current) return;

      const chartWrapper = lineChartWrapperRef.current;
      if (!chartWrapper) {
        return;
      }

      // Prevent the page from scrolling
      event.preventDefault();
      event.stopPropagation();

      const boundingRect = chartWrapper.getBoundingClientRect();
      const xRelative = (event.clientX - boundingRect.left) / boundingRect.width;

      const { deltaY } = event;
      const totalPoints = eventsList.length;
      // Calculate a dynamic zoom factor based on the number of points
      // Adjust the multiplier to increase the zoom speed
      const speedMultiplier = 0.005;
      const dynamicFactor = 1 + speedMultiplier * Math.sqrt(totalPoints);
      const zoomFactor = deltaY > 0 ? dynamicFactor : 1 / dynamicFactor;

      const originalMin = dayjs(eventsList[0]?.timestamp).unix();
      const originalMax = dayjs(eventsList[eventsList.length - 1]?.timestamp).unix();

      let [min, max] = activeZoomDomain || [originalMin, originalMax];
      const minTimestamp = typeof min === 'number' ? min : dayjs(min).unix();
      const maxTimestamp = typeof max === 'number' ? max : dayjs(max).unix();

      if (isNaN(minTimestamp) || isNaN(maxTimestamp)) {
        return;
      }

      const mid = minTimestamp + xRelative * (maxTimestamp - minTimestamp);
      const range = (maxTimestamp - minTimestamp) * zoomFactor;

      let newMin = mid - xRelative * range;
      let newMax = mid + (1 - xRelative) * range;

      const maxZoom = ((originalMax - originalMin) / 2) * 0.01;

      if (newMin < originalMin) newMin = originalMin;
      if (newMax > originalMax) newMax = originalMax;

      if (!isZoomingRef.current) {
        isZoomingRef.current = true;
        pendingZoomRange.current = [newMin, newMax];

        // // Apply the zoom domain after debouncing
        // debouncedSetZoomDomain(newMin, newMax, maxZoom);
        // Use the debounced function
        debouncedUpdateZoomDomain(
          newMin,
          newMax,
          maxZoom,
          setZoomDomain,
          updateUrlParams,
          urlParams,
          setIsDot
        );
      }
    },
    // eslint-disable-next-line
    [disableWheel, activeZoomDomain, eventsList, debouncedUpdateZoomDomain]
  );

  // Handle mouse entering the chart
  const handleMouseEnter = useCallback(() => {
    isMouseOverChart.current = true;
  }, []);

  // Handle mouse leaving the chart
  const handleMouseLeave = useCallback(() => {
    isMouseOverChart.current = false;
  }, []);

  // Handle mouse down for panning
  const handleMouseDown = useCallback(
    (event: any) => {
      if (disableMouseEvents || isZoomingRef.current) return;
      if ((event.target as Element).closest('.recharts-brush')) return;
      setIsPanning(true);
      setStartX(event.clientX);
      lineChartWrapperRef.current?.classList.add('panning');
    },
    [disableMouseEvents]
  );

  // Handle mouse move for panning
  const handleMouseMove = useCallback(
    (event: any) => {
      if (
        disableMouseEvents ||
        isZoomingRef.current ||
        !isPanning ||
        startX === null ||
        !activeZoomDomain
      )
        return;

      const boundingRect = lineChartWrapperRef.current?.getBoundingClientRect();
      if (!boundingRect) return;

      const deltaX = startX - event.clientX;
      const range = Number(activeZoomDomain[1]) - Number(activeZoomDomain[0]);
      const pixelPerTime = range / boundingRect.width;

      const newMin = Number(activeZoomDomain[0]) + deltaX * pixelPerTime;
      const newMax = Number(activeZoomDomain[1]) + deltaX * pixelPerTime;

      setZoomDomain([newMin, newMax]);
      updateUrlParams({
        ...urlParams,
        zoomDomainMin: newMin,
        zoomDomainMax: newMax,
      });

      setStartX(event.clientX);
    },
    [
      disableMouseEvents,
      isPanning,
      startX,
      activeZoomDomain,
      setZoomDomain,
      updateUrlParams,
      urlParams,
    ]
  );

  // Handle mouse up to stop panning
  const handleMouseUp = useCallback(() => {
    if (disableMouseEvents || isZoomingRef.current) return;
    setIsPanning(false);
    setStartX(null);
    lineChartWrapperRef.current?.classList.remove('panning');
  }, [disableMouseEvents]);

  // Add and remove event listeners
  useEffect(() => {
    const chartWrapper = lineChartWrapperRef.current;
    if (!chartWrapper) {
      return;
    }

    if (!disableWheel) {
      chartWrapper.addEventListener('wheel', handleWheel, { passive: false });
    }
    chartWrapper.addEventListener('mouseenter', handleMouseEnter);
    chartWrapper.addEventListener('mouseleave', handleMouseLeave);

    if (!disableMouseEvents) {
      chartWrapper.addEventListener('mousedown', handleMouseDown);
      chartWrapper.addEventListener('mousemove', handleMouseMove);
      chartWrapper.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      if (!disableWheel) {
        chartWrapper.removeEventListener('wheel', handleWheel);
      }
      chartWrapper.removeEventListener('mouseenter', handleMouseEnter);
      chartWrapper.removeEventListener('mouseleave', handleMouseLeave);
      if (!disableMouseEvents) {
        chartWrapper.removeEventListener('mousedown', handleMouseDown);
        chartWrapper.removeEventListener('mousemove', handleMouseMove);
        chartWrapper.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [
    handleWheel,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    disableWheel,
    disableMouseEvents,
  ]);

  return {
    lineChartWrapperRef,
  };
};
