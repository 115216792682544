const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3002';

const getCurrentUser = () => {
  const user = localStorage.getItem('user');
  if (user) {
    return JSON.parse(user);
  }
  return null;
};

const getToken = () => {
  return localStorage.getItem('access_token');
};

const getRefreshToken = () => {
  return localStorage.getItem('refresh_token');
};

const setToken = (token: string) => {
  localStorage.setItem('access_token', token);
};

const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem('refresh_token', refreshToken);
};

const removeToken = () => {
  localStorage.removeItem('access_token');
};

const removeRefreshToken = () => {
  localStorage.removeItem('refresh_token');
};

const isTokenExpired = () => {
  const token = getToken();
  if (token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const { exp } = payload;
      return Date.now() >= exp * 1000;
    } catch (error) {
      console.error('Failed to decode token', error);
      return true;
    }
  }
  return true;
};

const updateToken = async () => {
  const refreshToken = getRefreshToken();
  if (!refreshToken) {
    return null;
  }

  try {
    const response = await fetch(`${API_URL}/auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh_token: refreshToken }),
    });

    if (!response.ok) {
      throw new Error('Failed to refresh token');
    }

    const data = await response.json();
    setToken(data.access_token);
    setRefreshToken(data.refresh_token);
    return data.access_token;
  } catch (error) {
    console.error('Error refreshing token:', error);
    removeToken();
    removeRefreshToken();
    return null;
  }
};

const TokenService = {
  getCurrentUser,
  getToken,
  getRefreshToken,
  setToken,
  setRefreshToken,
  removeToken,
  removeRefreshToken,
  isTokenExpired,
  updateToken,
};

export default TokenService;
