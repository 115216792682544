import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import styles from './DeviceStatus.module.scss';
import { Tooltip } from 'antd';
import { isDeviceOffline } from 'utils/device.utils';
import { Device } from 'interfaces/devices/Devices.interface';

interface DeviceStatusProps {
  device: Device | null;
}

export const DeviceStatus: React.FC<DeviceStatusProps> = ({ device }) => {
  const messageWarningTooltip = (
    <div className={styles.devicesOfflineWarning}>
      <p>Device is offline. </p>
      <p>Please contact the administrator. </p>
    </div>
  );
  const deviceOnlineBadge = (
    <span className={styles.deviceOnlineBadge}>
      <CheckCircleFilled />
      Online
    </span>
  );

  const deviceOfflineBadge = (
    <Tooltip placement='bottom' title={messageWarningTooltip} arrow={false} color='white'>
      <span className={styles.deviceOfflineBadge}>
        <WarningFilled />
        Offline
      </span>
    </Tooltip>
  );

  return isDeviceOffline(device) ? deviceOfflineBadge : deviceOnlineBadge;
};
