import { useCallback, useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { dateRangeOptions } from 'config/constant';
import { DeviceEvent } from 'interfaces/devices/DeviceEvents.interface';
import { DateRangeUnix } from 'interfaces/Time.interface';

interface UseDateRangeProps {
  selectedOption: string;
  urlParams: Record<string, any>;
  updateUrlParams: (params: Record<string, any>) => void;
  eventsList: DeviceEvent[];
}

interface DateRangeState {
  dateRange: [number, number] | null;
  tooltipDateRange: DateRangeUnix;
  ticks: number[];
  handleSelectChange: (value: string) => void;
  handleDateRangeChange: (dates: any, dateStrings: [string, string]) => void;
  getNoDataMessage: () => string;
}

export const useDateRange = ({
  selectedOption,
  urlParams,
  updateUrlParams,
  eventsList,
}: UseDateRangeProps): DateRangeState => {
  const [dateRange, setDateRange] = useState<[number, number] | null>(null);
  const [tooltipDateRange, setTooltipDateRange] = useState<DateRangeUnix>({
    startDate: dayjs().subtract(1, 'day').unix(),
    endDate: dayjs().unix(),
  });
  const [ticks, setTicks] = useState<number[]>([]);
  const [, setZoomDomain] = useState<[number, number] | null>(null);

  // Memoize the date range and related values to prevent unnecessary recalculations
  const [from, to] = useMemo(() => {
    let from: number, to: number;
    if (selectedOption === dateRangeOptions.lastDayOptionId) {
      from = dayjs().subtract(1, 'day').unix();
      to = dayjs().unix();
    } else if (selectedOption === dateRangeOptions.lastWeekOptionId) {
      from = dayjs().subtract(1, 'week').unix();
      to = dayjs().unix();
    } else if (selectedOption === dateRangeOptions.lastMonthOptionId) {
      from = dayjs().subtract(1, 'month').unix();
      to = dayjs().unix();
    } else if (
      selectedOption === dateRangeOptions.dateRangeOptionId &&
      (dateRange || (urlParams.startDate && urlParams.endDate))
    ) {
      from = dateRange ? dateRange[0] : Number(urlParams.startDate);
      to = dateRange ? dateRange[1] : Number(urlParams.endDate);
    } else {
      return [null, null];
    }
    return [from, to];
  }, [selectedOption, dateRange, urlParams.startDate, urlParams.endDate]);

  useEffect(() => {
    if (from !== null && to !== null) {
      setTooltipDateRange({ startDate: from, endDate: to });
      setZoomDomain([from, to]);
    }
  }, [from, to]);

  useEffect(() => {
    if (eventsList.length > 0) {
      setTicks(
        selectedOption === dateRangeOptions.lastDayOptionId ||
          selectedOption === dateRangeOptions.lastWeekOptionId
          ? eventsList
              .filter((event) => dayjs.unix(event.timestamp).minute() === 0)
              .map((event) => event.timestamp)
          : eventsList.map((event) => event.timestamp)
      );
    }
  }, [selectedOption, dateRange, eventsList]);

  const handleDateRangeChange = useCallback((dates: any, dateStrings: [string, string]) => {
    const timestamps = dateStrings.map((date) => dayjs(date).unix()) as [number, number];
    setDateRange(timestamps);
    setTooltipDateRange({ startDate: timestamps[0], endDate: timestamps[1] });
  }, []);

  const handleSelectChange = (value: string) => {
    updateUrlParams({
      timeOption: value,
      startDate: undefined,
      endDate: undefined,
    });
    if (value !== dateRangeOptions.dateRangeOptionId) {
      setDateRange(null);
    }
  };

  const getNoDataMessage = () => {
    switch (selectedOption) {
      case dateRangeOptions.lastDayOptionId:
        return 'No data available from the last day';
      case dateRangeOptions.lastWeekOptionId:
        return 'No data available from the last week';
      case dateRangeOptions.lastMonthOptionId:
        return 'No data available from the last month';
      case dateRangeOptions.dateRangeOptionId:
        return 'No data available for the selected date range';
      default:
        return 'No data available.';
    }
  };

  return {
    dateRange,
    tooltipDateRange,
    ticks,
    handleSelectChange,
    handleDateRangeChange,
    getNoDataMessage,
  };
};
