import { Button as AntButton, ButtonProps as AntButtonProps } from 'antd';
import variables from '../../../styles/variables.module.scss';
import styled from 'styled-components';

export interface StyledButtonProps extends AntButtonProps {
  primary?: boolean;
  fullWidth?: boolean;
}

const StyledButton = styled(({ primary, fullWidth, ...rest }: StyledButtonProps) => (
  <AntButton {...rest} />
))<StyledButtonProps>`
  background-color: ${(props) => (props.primary ? variables.primaryColor : '#ffffff')};
  border-color: ${(props) => (props.primary ? variables.primaryColor : '#d9d9d9')};
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  color: ${(props) => (props.primary ? '#ffffff' : '#000000')};
  border-radius: 2px;
  min-height: 35px;

  &&&:disabled {
    background-color: ${(props) => (props.primary ? variables.primaryColor : '#ffffff')};
    border-color: ${(props) => (props.primary ? variables.primaryColor : '#d9d9d9')};
    opacity: 0.4;
    color: ${(props) => (props.primary ? '#ffffff' : '#000000')};
    &&&:hover {
      background-color: ${(props) => (props.primary ? variables.primaryColor : '#ffffff')};
      border-color: ${(props) => (props.primary ? variables.primaryColor : '#d9d9d9')};
      opacity: 0.4;
      color: ${(props) => (props.primary ? '#ffffff' : '#000000')};
    }
  }
  &&&:hover {
    background-color: ${(props) => (props.primary ? variables.primaryColorHover : '#fafafa')};
    border-color: ${(props) => (props.primary ? variables.primaryColorHover : '#d9d9d9')};
    color: ${(props) => (props.primary ? '#ffffff' : '#000000')};
  }

  &&&:focus {
    background-color: ${(props) => (props.primary ? variables.primaryColorHover : '#fbfbfb')};
    border-color: ${(props) => (props.primary ? '#45a049' : '#d9d9d9')};
    color: ${(props) => (props.primary ? '#ffffff' : '#000000')};
  }
`;

export default StyledButton;
