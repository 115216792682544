import React from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from './TenantSelector.module.scss';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { tenantSelector, setSelectedTenant } from 'store/slices/tenants.slice';
import { Tenant } from 'interfaces/Tenant.interface';
import { useNavigate } from 'react-router-dom';

const TenantSelector = () => {
  const dispatch = useAppDispatch();
  const { tenantsList, selectedTenant } = useAppSelector(tenantSelector);
  const navigate = useNavigate();

  const handleMenuClick = (tenant: Tenant) => {
    dispatch(setSelectedTenant(tenant));
    navigate('/dasboard');
  };

  const menu =
    tenantsList.length > 1 ? (
      <Menu>
        {tenantsList.map((tenant) => (
          <Menu.Item key={tenant.id} onClick={() => handleMenuClick(tenant)}>
            {tenant.name}
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <></>
    );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button className={styles.tenantSelector}>
        {selectedTenant?.name || 'Select tenant'}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default TenantSelector;
