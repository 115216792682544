import React from 'react';
import { InputProps as AntInputProps } from 'antd';
import { CustomPasswordInput, CustomStyledInput } from './Input.styles';

interface CustomInputProps extends AntInputProps {
  fullWidth?: boolean;
}

const Input: React.FC<CustomInputProps> & {
  Password: React.FC<CustomInputProps>;
} = ({ fullWidth, ...rest }) => {
  return <CustomStyledInput {...rest} />;
};

Input.Password = CustomPasswordInput;

export default Input;
