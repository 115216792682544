import { useMemo } from 'react';
import dayjs from 'dayjs';

/**
 * Custom hook that filters and processes a list of events based on a specified zoom domain.
 *
 * @param {Array} eventsList - An array of event objects. Each event object must have a `timestamp` property, which can be a string or a number.
 * @param {[number, number] | string[] | null} activeZoomDomain - The active zoom domain, represented as a tuple of two numbers (representing the start and end timestamps) or two strings (which will be parsed as dates). If `null`, no filtering is applied.
 *
 * @returns {Object} An object containing:
 *   - `numericEventsList`: The original events list with timestamps converted to numeric values (Unix timestamp).
 *   - `filteredEventsList`: The list of events filtered based on the `activeZoomDomain`.
 *   - `startIndex`: The index of the first event in the `filteredEventsList` within the `numericEventsList`.
 *   - `endIndex`: The index of the last event in the `filteredEventsList` within the `numericEventsList`.
 *
 */
export const useFilteredEvents = (
  eventsList: any[],
  activeZoomDomain: [number, number] | string[] | null
) => {
  // Convert all event timestamps to numeric values (Unix timestamps).
  const numericEventsList = useMemo(
    () =>
      eventsList.map((event) => ({
        ...event,
        timestamp:
          typeof event.timestamp === 'string' ? dayjs(event.timestamp).unix() : event.timestamp,
      })),
    [eventsList]
  );

  // Filter the events list based on the active zoom domain.
  const filteredEventsList = useMemo(
    () =>
      activeZoomDomain
        ? numericEventsList.filter(
            (event) =>
              event.timestamp >= activeZoomDomain[0] && event.timestamp <= activeZoomDomain[1]
          )
        : numericEventsList,
    [numericEventsList, activeZoomDomain]
  );

  // Determine the start and end indices of the filtered events in the original list.
  return {
    numericEventsList,
    filteredEventsList,
    startIndex: numericEventsList.findIndex(
      (event) => event.timestamp === filteredEventsList[0]?.timestamp
    ),
    endIndex: numericEventsList.findIndex(
      (event) => event.timestamp === filteredEventsList[filteredEventsList.length - 1]?.timestamp
    ),
  };
};
