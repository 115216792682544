import { useSelector } from 'react-redux';

export const useAuth = () => {
  const { user } = useSelector((state: any) => state.auth);

  const isAuthenticated = !!user;

  return {
    user,
    isAuthenticated,
  };
};
