import { Device } from 'interfaces/devices/Devices.interface';
import { baseApi } from './baseApi.service';

export const devicesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDevices: builder.query<Device[], { tenantId: string }>({
      query: ({ tenantId }) => ({
        url: `/devices?tenantId=${tenantId}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }),
      transformResponse: (response: any) => {
        const result = response[0]?.devices || [];
        return result as Device[];
      },
    }),
    editDevice: builder.mutation<any, { deviceId: string; newName: string }>({
      query: ({ deviceId, newName }) => ({
        url: `/editDevice?deviceId=${deviceId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newName }),
        credentials: 'include',
      }),
    }),
  }),
});

export const { useGetDevicesQuery, useEditDeviceMutation } = devicesApi;
