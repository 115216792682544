import { Device } from 'interfaces/devices/Devices.interface';
import styles from './PowerStatus.module.scss';
import { getPowerStatus } from 'utils/device.utils';
import { powerStatus } from 'config/constant';
import { ReactComponent as BatteryLow } from 'assets/icons/battery-empty.svg';
import { ReactComponent as BatteryMedium } from 'assets/icons/battery-half.svg';
import { ReactComponent as BatteryHigh } from 'assets/icons/battery-full.svg';

interface PowerStatusProps {
  device: Device | null;
}
export const PowerStatus: React.FC<PowerStatusProps> = ({ device }) => {
  const devicePowerStatus = getPowerStatus(device);
  const getBatteryIcon = () => {
    if (devicePowerStatus === powerStatus.POWER_NO_INFO) {
      return (
        <div className={styles.batteryNotSet}>
          <BatteryLow className={`${styles.batteryIcon} ${styles.batteryLow}`} /> ...
        </div>
      );
    } else if (devicePowerStatus === powerStatus.BATTERY_HIGH) {
      return <BatteryHigh className={`${styles.batteryIcon} ${styles.batteryFull}`} />;
    } else if (devicePowerStatus === powerStatus.BATTERY_MEDIUM) {
      return <BatteryMedium className={`${styles.batteryIcon} ${styles.batteryMedium}`} />;
    } else if (
      devicePowerStatus === powerStatus.BATTERY_LOW ||
      devicePowerStatus === powerStatus.BATTERY_EMPTY
    ) {
      return <BatteryLow className={`${styles.batteryIcon} ${styles.batteryLow}`} />;
    }
  };

  const getBatteryText = (device: Device | null) => {
    if (devicePowerStatus === powerStatus.POWER_NO_INFO) {
      return '';
    } else {
      let batteryLevel = device?.deviceStatus?.batteryLevel || -1;
      if (batteryLevel === -1) {
        return '0%';
      } else {
        return batteryLevel + '%';
      }
    }
  };

  return (
    <div className={styles.batteryStatus}>
      {getBatteryIcon()}
      <span className={styles.batteryText}>{getBatteryText(device)}</span>
    </div>
  );
};
