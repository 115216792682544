import { Input as AntInput, Form } from 'antd';
import styled from 'styled-components';
import { InputProps as AntInputProps } from 'antd';
import variables from '../../../styles/variables.module.scss';

interface CustomInputProps extends AntInputProps {
  fullWidth?: boolean;
}

const InputSyles = `
  border: 1px solid ${variables.gray1};
  padding: 6px;
  font-size: 14px;
  border-radius: 4px;

  &:focus {
    border-color: ${variables.primaryColor};
    box-shadow: none;
  }
  &:focus-within {
    border-color: ${variables.primaryColor};
    box-shadow: none;
  }
  &:hover {
    border-color: ${variables.primaryColor};
    box-shadow: none;
  }
  `;

export const StyledFormItem = styled(Form.Item)`
  .ant-form-item {
    margin-bottom: 10px;
  }
  .ant-form-item-explain-error {
    text-align: left;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 2px;
    font-weight: 300;
  }
`;

export const CustomStyledInput = styled(AntInput)<CustomInputProps>`
  ${InputSyles}
`;

export const CustomPasswordInput = styled(AntInput.Password)<CustomInputProps>`
  ${InputSyles}
`;
