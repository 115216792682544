import React from 'react';
import StyledButton from './Button.styles';
import { ButtonProps as AntButtonProps } from 'antd';

interface CustomButtonProps extends AntButtonProps {
  primary?: boolean;
  fullWidth?: boolean;
}

const Button: React.FC<CustomButtonProps> = ({ primary, fullWidth, children, ...rest }) => {
  return (
    <StyledButton primary={primary} fullWidth={fullWidth} {...rest}>
      {children}
    </StyledButton>
  );
};

export default Button;
