import { timeSeconds } from 'config/constant';

type Parameters = {
  temperature: number;
  airHumidity: number;
  luminosity: number;
  soilMoisture: number;
  pressure: number;
  [key: string]: number | null;
};

type DataPoint = {
  timestamp: number;
  parameters: Parameters;
};

/**
 * Generates mock data points with smooth sine wave patterns for each parameter.
 *
 * @returns {DataPoint[]} An array of DataPoint objects with timestamps and parameter values.
 *
 *
 */
export function generateMockData(): DataPoint[] {
  const now = Date.now() / 1000; // Current time in seconds since the Unix epoch
  const past24Hours = now - timeSeconds.ONE_DAY;
  const numberOfPoints = 32; // Number of data points to generate
  const mockData: DataPoint[] = [];

  /**
   * Generates a value based on a sine wave pattern.
   *
   * @param {number} min - The minimum value of the parameter.
   * @param {number} max - The maximum value of the parameter.
   * @param {number} index - The current index of the data point.
   * @param {number} totalPoints - The total number of data points.
   * @param {number} [frequency=1] - The frequency of the sine wave.
   * @param {number} [phaseShift=0] - The phase shift of the sine wave.
   * @returns {number} - A value following a sine wave pattern within the specified range.
   */
  const generateSineWaveValue = (
    min: number,
    max: number,
    index: number,
    totalPoints: number,
    frequency: number = 1,
    phaseShift: number = 0
  ): number => {
    const amplitude = (max - min) / 2;
    const offset = (max + min) / 2;
    const sineValue = Math.sin(
      frequency * ((index / (totalPoints - 1)) * Math.PI * 2 + phaseShift)
    );
    return +(offset + amplitude * sineValue).toFixed(2);
  };

  // Generate data points for the last 24 hours
  for (let i = 0; i < numberOfPoints; i++) {
    const timestamp = past24Hours + ((now - past24Hours) / (numberOfPoints - 1)) * i;

    const temperature = generateSineWaveValue(10.0, 24.0, i, numberOfPoints, 1, 0);
    const airHumidity = generateSineWaveValue(10.0, 80.0, i, numberOfPoints, 0.5, Math.PI / 4);
    const luminosity = generateSineWaveValue(0, 100, i, numberOfPoints, 0.49, Math.PI / 1);
    const soilMoisture = generateSineWaveValue(10.0, 80.0, i, numberOfPoints, 0.75, Math.PI / 1);
    const pressure = generateSineWaveValue(80000.0, 100000.0, i, numberOfPoints, 1.2, Math.PI / 6);
    const battery = generateSineWaveValue(0, 100, i, numberOfPoints, 0.2, Math.PI / 8);

    const dataPoint: DataPoint = {
      timestamp,
      parameters: {
        temperature,
        airHumidity,
        luminosity,
        soilMoisture,
        pressure,
        battery,
      },
    };
    mockData.push(dataPoint);
  }

  return mockData;
}
