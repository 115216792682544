import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import { StyledFormItem } from 'components/common/Input/Input.styles';
import { Form } from 'antd';
import styles from './ResetPassword.module.scss';
import { useLogoutMutation, useResetPasswordMutation } from 'services/auth.service';
import { Alert } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { User } from 'interfaces/auth/User';
import { setUser } from 'store/slices/auth.slice';

interface ResetPasswordInterface {
  hasLogout?: boolean;
}

const ResetPassword: React.FC<ResetPasswordInterface> = ({ hasLogout = false }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [resetPassword] = useResetPasswordMutation();
  const [logout] = useLogoutMutation();
  const { user } = useSelector((state: any) => state.auth) as { user: User };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const errorInfo =
    'Pasword should be at least 10 characters long, contain at least one lowercase letter, one uppercase letter, one number, and one special character.';

  function isStongPassword(password: string): boolean {
    const minLength = 10;
    const hasMinLength = password.length >= minLength;
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[^a-zA-Z0-9]/.test(password);

    return hasMinLength && hasLowercase && hasUppercase && hasNumber && hasSymbol;
  }

  const handleSubmit = async () => {
    if (currentPassword === newPassword) {
      setError('New password cannot be the same as the current password');
      return;
    } else {
      setError(null);
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    } else {
      setError(null);
    }

    if (isStongPassword(newPassword) === false) {
      setError('Password not strong enough');
      return;
    } else {
      setError(null);
    }

    const token = localStorage.getItem('token');
    const response = (await resetPassword({
      username: user.userName,
      password: currentPassword,
      newPassword,
      token,
    })) as any;
    if (response.error) {
      if (response.error.data.error === 'Unauthorized') {
        setError('Incorrect current password');
      }
    } else {
      let newUser = { ...user, firstLogin: false };
      dispatch(setUser(newUser));
      navigate('/');
    }
  };

  const onLogout = () => {
    try {
      logout({});
      dispatch(setUser(null));
      navigate('/login');
    } catch (error: any) {
      console.log('Error logging out', error);
    }
  };

  const onFinishFailed = (errorInfo: string) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name='basic'
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      onFinishFailed={() => onFinishFailed}
      className={styles.resetForm}
    >
      <StyledFormItem
        name='currentPassword'
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password
          placeholder='Current Password'
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
      </StyledFormItem>
      <StyledFormItem
        name='newPassword'
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password
          placeholder='New Password'
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </StyledFormItem>
      <StyledFormItem
        name='confirmPassword'
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password
          placeholder='Confirm Password'
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      </StyledFormItem>
      <div className={styles.actionContainer}>
        <Button primary fullWidth htmlType='submit'>
          Set Password
        </Button>
      </div>
      {error && <Alert message={error} type='error' showIcon />}
      {error === 'Password not strong enough' && <p className={styles.errorInfo}>{errorInfo}</p>}
      {hasLogout && (
        <>
          <div className={styles.divider}></div>
          <div className={styles.logoutContainer}>
            <p className={styles.smallText}>Logged in as: {user.userName} </p>
            <p className={styles.link} onClick={() => onLogout()}>
              Logout
            </p>
          </div>
        </>
      )}
    </Form>
  );
};
export default ResetPassword;
