import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authReducer from './slices/auth.slice';
import devicesReducer from './slices/devices.slice';
import tenantsReducer from './slices/tenants.slice';
import graphOptionsReducer from './slices/graphOptions.slice';
import { baseApi } from 'services/baseApi.service';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth', 'devices', 'graphOptions', 'tenants'],
};

const reducers = combineReducers({
  auth: authReducer,
  devices: devicesReducer,
  graphOptions: graphOptionsReducer,
  tenants: tenantsReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }).concat(baseApi.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
