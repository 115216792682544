import { ReactComponent as Overview } from 'assets/icons/overview.svg';
import { ReactComponent as Light } from 'assets/icons/light.svg';
import { ReactComponent as AirHumidity } from 'assets/icons/air-humidity.svg';
import { ReactComponent as Battery } from 'assets/icons/battery-charging.svg';
import { ReactComponent as SoilMoisture } from 'assets/icons/soil-moisture.svg';
import { ReactComponent as Pressure } from 'assets/icons/pressure.svg';
import { ReactComponent as Temperature } from 'assets/icons/temperature.svg';
import styles from './GraphTabs.module.scss';

export const GraphTabs = [
  {
    label: 'Overview',
    key: '1',
    graphParameter: 'parameters.luminosity',
    graphLabel: '',
    icon: <Overview />,
    className: 'overviewTab',
  },
  {
    label: 'Luminosity',
    key: '2',
    graphParameter: 'parameters.luminosity',
    graphLabel: 'Luminosity',
    icon: <Light className={styles.tabIcon} />,
    className: 'luminosity',
  },
  {
    label: 'Temperature',
    key: '3',
    graphParameter: 'parameters.temperature',
    graphLabel: 'Temperature',
    icon: <Temperature className={styles.tabIcon} />,
    className: 'temperature',
  },
  {
    label: 'Soil Moisture',
    key: '4',
    graphParameter: 'parameters.soilMoisture',
    graphLabel: 'Soil Moisture',
    icon: <SoilMoisture className={styles.tabIcon} />,
    className: 'soilMoisture',
  },
  {
    label: 'Pressure',
    key: '5',
    graphParameter: 'parameters.pressure',
    graphLabel: 'Pressure',
    icon: <Pressure className={styles.tabIcon} />,
    className: 'pressure',
  },
  {
    label: 'Air Humidity',
    key: '6',
    graphParameter: 'parameters.airHumidity',
    graphLabel: 'Air Humidity',
    icon: <AirHumidity className={styles.tabIcon} />,
    className: 'airHumidity',
  },
  {
    label: 'Battery',
    key: '7',
    graphParameter: 'parameters.battery',
    graphLabel: 'Battery',
    icon: <Battery className={styles.tabIcon} />,
    className: 'battery',
  },
];
