import styled from 'styled-components';
import { ConfigProvider, DatePicker } from 'antd';
import variables from '../../../styles/variables.module.scss';

const { RangePicker } = DatePicker;

const StyledRangePicker = styled(RangePicker)`
  &&&.ant-picker-outlined:hover {
    border-color: ${variables.primaryColor};
  }
  &&&.ant-picker-focused {
    border-color: ${variables.primaryColor};
    box-shadow: none;
  }
  &&&.ant-picker-range .ant-picker-active-bar {
    background: ${variables.primaryColor};
  }
`;

const DateRangePicker = ({
  onChange,
  defaultValue,
  disabledDate,
  ...rest
}: {
  onChange: any;
  defaultValue: any;
  disabledDate: any;
  rest?: any;
}) => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#00b96b',
      },
    }}
  >
    <StyledRangePicker
      onChange={onChange}
      defaultValue={defaultValue}
      disabledDate={disabledDate}
      {...rest}
    />
  </ConfigProvider>
);

export default DateRangePicker;
