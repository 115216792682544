import { baseApi } from './baseApi.service';
import TokenService from './token.service';

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/auth/login',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: credentials,
      }),
      transformResponse: (response: any) => {
        localStorage.setItem('user', JSON.stringify(response.user));
        TokenService.setToken(response.access_token);
        TokenService.setRefreshToken(response.refresh_token);
        return response;
      },
    }),
    resetPassword: builder.mutation({
      query: ({ username, password, newPassword }) => ({
        url: '/auth/reset-password',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${TokenService.getToken()}`,
          'Content-Type': 'application/json',
        },
        body: { username, password, newPassword },
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/auth/logout',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${TokenService.getToken()}`,
          'Content-Type': 'application/json',
        },
        body: { refresh_token: TokenService.getRefreshToken() },
      }),
      transformResponse: (response) => {
        localStorage.removeItem('user');
        TokenService.removeToken();
        TokenService.removeRefreshToken();
        return response;
      },
    }),
    refreshToken: builder.mutation({
      query: () => ({
        url: '/auth/refresh',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: { refresh_token: TokenService.getRefreshToken() },
      }),
      transformResponse: (response: any) => {
        TokenService.setToken(response.access_token);
        TokenService.setRefreshToken(response.refresh_token);
        return response;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useResetPasswordMutation,
  useLogoutMutation,
  useRefreshTokenMutation,
} = authApi;
