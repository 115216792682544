import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';

interface GraphOptionsState {
  smoothOption?: string;
  apiHisteresis?: string;
  hasFetchDataOnZoom?: boolean;
  smoothWindowSize: number;
  polinomialDegree?: number;
  apiResponseSize?: number;
  trendMinSize?: number;
}

const initialState: GraphOptionsState = {
  smoothOption: 'linear',
  apiHisteresis: 'none',
  hasFetchDataOnZoom: false,
  smoothWindowSize: 30,
  polinomialDegree: 1,
  apiResponseSize: 1000,
  trendMinSize: 1,
};

export const graphOptionsSlice: any = createSlice({
  name: 'graphOptions',
  initialState,
  reducers: {
    setSmoothOption: (state, { payload }: PayloadAction<GraphOptionsState['smoothOption']>) => {
      state.smoothOption = payload;
    },
    setTrendMinSize: (state, { payload }: PayloadAction<GraphOptionsState['trendMinSize']>) => {
      state.trendMinSize = payload;
    },
    setApiHisteresis: (state, { payload }: PayloadAction<GraphOptionsState['apiHisteresis']>) => {
      state.apiHisteresis = payload;
    },
    setHasFetchDataOnZoom: (
      state,
      { payload }: PayloadAction<GraphOptionsState['hasFetchDataOnZoom']>
    ) => {
      state.hasFetchDataOnZoom = payload;
    },
    setSmoothWindowSize: (
      state,
      { payload }: PayloadAction<GraphOptionsState['smoothWindowSize']>
    ) => {
      state.smoothWindowSize = payload;
    },
    setPolinomialDegree: (
      state,
      { payload }: PayloadAction<GraphOptionsState['polinomialDegree']>
    ) => {
      state.polinomialDegree = payload;
    },
    setApiResponseSize: (
      state,
      { payload }: PayloadAction<GraphOptionsState['apiResponseSize']>
    ) => {
      state.apiResponseSize = payload;
    },
  },
});

export const {
  setSmoothOption,
  setTrendMinSize,
  setApiHisteresis,
  setHasFetchDataOnZoom,
  setSmoothWindowSize,
  setPolinomialDegree,
  setApiResponseSize,
} = graphOptionsSlice.actions;

export const graphOptionSelector = (state: RootState) => state.graphOptions as GraphOptionsState;

export default graphOptionsSlice.reducer;
