import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { Device } from '../../interfaces/devices/Devices.interface';

interface DeviceState {
  devicesList: Device[];
  selectedDevice: Device | null;
  loading: boolean;
  error: string | null;
}

const initialState: DeviceState = {
  devicesList: [],
  loading: false,
  error: null,
  selectedDevice: null,
};

export const devicesSlice: any = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setDevices: (state, { payload }: PayloadAction<DeviceState['devicesList']>) => {
      state.devicesList = payload;
    },
    setSelectedDevice: (state, { payload }: PayloadAction<Device>) => {
      state.selectedDevice = payload;
    },
  },
});

export const { setDevices, setSelectedDevice } = devicesSlice.actions;

export const deviceSelector = (state: RootState) => state.devices as DeviceState;

export default devicesSlice.reducer;
