import styled from 'styled-components';
import { Spin } from 'antd';
import variables from '../../../styles/variables.module.scss';

export const StyledSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: ${variables.primaryColor};
  }
`;

export interface SpinnerProps {
  spinning: boolean;
  children: React.ReactNode;
}

const Spinner: React.FC<SpinnerProps> = ({ spinning, children }) => {
  return <StyledSpin spinning={spinning}>{children}</StyledSpin>;
};

export default Spinner;
