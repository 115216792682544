import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import TokenService from './token.service';

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: 'include',
    prepareHeaders: async (headers) => {
      if (TokenService.isTokenExpired()) {
        await TokenService.updateToken();
      }
      const token = TokenService.getToken();
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ['Device'],
});
