import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Tenant } from 'interfaces/Tenant.interface';

interface TenantsState {
  tenantsList: Tenant[];
  selectedTenant: Tenant | null;
  loading: boolean;
  error: string | null;
}

const initialState: TenantsState = {
  tenantsList: [],
  loading: false,
  error: null,
  selectedTenant: null,
};

export const tenantsSlice: any = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    setTenants: (state, { payload }: PayloadAction<TenantsState['tenantsList']>) => {
      state.tenantsList = payload;
    },
    setSelectedTenant: (state, { payload }: PayloadAction<Tenant>) => {
      state.selectedTenant = payload;
    },
  },
});

export const { setTenants, setSelectedTenant } = tenantsSlice.actions;

export const tenantSelector = (state: RootState) => state.tenants as TenantsState;

export default tenantsSlice.reducer;
