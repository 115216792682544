import React from 'react';
import styles from './FirstLoginResetPassword.module.scss';
import ResetPassword from 'components/specific/ResetPassword/ResetPasword';

const FirstLoginResetPassword: React.FC = () => {
  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginForm}>
        <p className={styles.smallTitle}>Set New Password</p>
        <ResetPassword hasLogout={true} />
      </div>
    </div>
  );
};
export default FirstLoginResetPassword;
