import { useCallback, useMemo } from 'react';
import {
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Brush,
  AreaChart,
  Area,
  ResponsiveContainer,
  ReferenceDot,
} from 'recharts';
import dayjs from 'dayjs';
import styles from './Graph.module.scss';
import { getUnit } from 'utils/graph.utils';
import stylesVariables from 'styles/variables.module.scss';
import { CurveType } from 'recharts/types/shape/Curve';

import useUrlParams from 'hooks/useUrlParams';
import { useZoomAndPan } from 'hooks/useZoomAndPan';
import { useFilteredEvents } from 'hooks/useFilteredEvents';

declare global {
  interface Window {
    zoomTimeout?: number;
  }
}

type GraphProps = {
  eventsList: any[];
  parameter: string;
  parameterLabel: string;
  zoomDomain: [number, number] | null;
  setZoomDomain: (domain: [number, number] | null) => void;
  urlParams: any;
  ticks: number[];
  isDot: boolean;
  setIsDot: (value: boolean) => void;
  getTickFormatter: (timestamp: number) => string;
  getBrushClass: () => string;
  hasBrush?: boolean;
  hasXAxis?: boolean;
  hasYAxis?: boolean;
  hasTooltip?: boolean;
  graphMargin?: { top: number; right: number; left: number; bottom: number };
  style?: React.CSSProperties;
  disableWheel?: boolean;
  disableMouseEvents?: boolean;
  showPointRef?: boolean;
  graphLineStyle?: CurveType;
};

const Graph: React.FC<GraphProps> = ({
  eventsList,
  parameter,
  parameterLabel,
  zoomDomain,
  setZoomDomain,
  ticks,
  isDot,
  setIsDot,
  getTickFormatter,
  getBrushClass,
  hasBrush = true,
  hasXAxis = true,
  hasYAxis = true,
  hasTooltip = true,
  graphMargin = { top: 50, right: 30, left: 0, bottom: 50 }, // Default margin
  style,
  disableWheel = false,
  disableMouseEvents = false,
  showPointRef = false,
  graphLineStyle = 'monotone',
}) => {
  const [urlParams, updateUrlParams] = useUrlParams();

  const { lineChartWrapperRef } = useZoomAndPan({
    eventsList,
    activeZoomDomain: zoomDomain,
    setZoomDomain,
    updateUrlParams,
    disableWheel,
    disableMouseEvents,
    // urlParams,
    setIsDot,
  });

  const zoomDomainUrl = useMemo(() => {
    return urlParams.zoomDomainMin && urlParams.zoomDomainMax
      ? [urlParams.zoomDomainMin, urlParams.zoomDomainMax]
      : null;
  }, [urlParams.zoomDomainMin, urlParams.zoomDomainMax]);

  const activeZoomDomain = zoomDomain || zoomDomainUrl;
  const { numericEventsList, startIndex, endIndex } = useFilteredEvents(
    eventsList,
    activeZoomDomain
  );

  const handleBrushChange = useCallback(
    (numericEventsList: any[], brushStartIndex?: number, brushEndIndex?: number) => {
      if (
        brushStartIndex !== undefined &&
        brushEndIndex !== undefined &&
        brushStartIndex !== -1 &&
        brushEndIndex !== -1
      ) {
        const newZoomDomain: [number, number] = [
          numericEventsList[brushStartIndex].timestamp,
          numericEventsList[brushEndIndex].timestamp,
        ];
        setZoomDomain(newZoomDomain);
        updateUrlParams({
          ...urlParams,
          zoomDomainMin: newZoomDomain[0],
          zoomDomainMax: newZoomDomain[1],
        });
      }
    },
    [setZoomDomain, updateUrlParams, urlParams]
  );

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={styles.customTooltip}>
          <p className={styles.customTooltipLabel}>
            {`${payload[0].value} ${getUnit(payload[0].dataKey)}`}
          </p>
          <p className={styles.customTooltipFooter}>
            {`Time: ${dayjs.unix(label).format('YYYY-MM-DD HH:mm:ss')}`}
          </p>
        </div>
      );
    }
    return null;
  };
  const lastDataPoint = numericEventsList[numericEventsList.length - 1];

  const CustomDot = (props: any) => {
    const { cx, cy } = props;
    if (!cy || !cx) return null;
    return (
      <circle
        cx={cx}
        cy={cy}
        r={8}
        stroke={'#fff'}
        strokeWidth={2}
        fill={stylesVariables.primaryColor}
      />
    );
  };

  return (
    <div style={{ height: '100%' }} className={styles.tabPaneLoading} ref={lineChartWrapperRef}>
      {numericEventsList.length > 0 && (
        <ResponsiveContainer width='100%' height='100%'>
          <AreaChart
            data={numericEventsList}
            key={urlParams.tab}
            margin={graphMargin}
            style={{ ...style }}
          >
            <defs>
              <linearGradient id='colorGradient' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='0%' stopColor={stylesVariables.primaryColor} stopOpacity={0.2} />
                <stop offset='100%' stopColor={stylesVariables.primaryColor} stopOpacity={0} />
              </linearGradient>
            </defs>
            {hasYAxis && hasXAxis && <CartesianGrid strokeDasharray='0 0' stroke='#f0f0f0' />}
            {!hasYAxis && !hasXAxis && <CartesianGrid vertical={false} horizontal={false} />}
            <XAxis
              dataKey='timestamp'
              tickFormatter={(timestamp) => getTickFormatter(timestamp)}
              minTickGap={70}
              ticks={ticks}
              style={{ opacity: hasXAxis ? 1 : 0 }}
            />
            <YAxis
              tickFormatter={(value) => Math.round(value).toString()}
              type='number'
              domain={([dataMin, dataMax]) => {
                const min = Math.min(dataMin);
                const max = Math.max(dataMax);
                const treshold = (max - min) * 0.05;
                const newMin = min - treshold < 0 ? 0 : min - treshold;
                return [newMin, max + treshold];
              }}
              axisLine={hasYAxis}
              hide={!hasYAxis}
            />
            {hasTooltip && <Tooltip content={<CustomTooltip />} isAnimationActive={false} />}
            <Legend payload={[{ value: parameterLabel, type: 'line', id: 'ID01' }]} />

            <Area
              type={graphLineStyle}
              dataKey={parameter}
              stroke={stylesVariables.primaryColor}
              fill='url(#colorGradient)'
              fillOpacity={0.6}
              strokeWidth={1.5}
              activeDot={isDot ? <CustomDot /> : false}
              isAnimationActive={false}
            />

            {showPointRef && (
              <ReferenceDot
                x={lastDataPoint.timestamp}
                y={lastDataPoint.parameters[parameter.replace('parameters.', '')]}
                r={5}
                fill='#cc8500'
                stroke='#cc8500'
              />
            )}
            {hasBrush && (
              <Brush
                dataKey='timestamp'
                height={20}
                stroke={styles.primaryColor}
                tickFormatter={(timestamp) => getTickFormatter(timestamp)}
                className={getBrushClass()}
                startIndex={startIndex !== -1 ? startIndex : 0}
                endIndex={endIndex !== -1 ? endIndex : numericEventsList.length - 1}
                onChange={(indices) =>
                  handleBrushChange(numericEventsList, indices.startIndex, indices.endIndex)
                }
              >
                <AreaChart key={urlParams.tab} data={numericEventsList}>
                  <CartesianGrid />
                  <XAxis hide dataKey='timestamp' ticks={[]} />
                  <YAxis domain={['dataMin', 'dataMax']} hide />
                  <Area
                    type={graphLineStyle}
                    key={urlParams.tab}
                    dataKey={parameter}
                    stroke='#ddd'
                    fill='#ddd'
                    isAnimationActive={false}
                  />
                </AreaChart>
              </Brush>
            )}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default Graph;
