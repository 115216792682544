export const dateRangeOptions = {
  lastDayOptionId: 'lastDayOptionId',
  lastWeekOptionId: 'lastWeekOptionId',
  lastMonthOptionId: 'lastMonthOptionId',
  dateRangeOptionId: 'dateRangeOptionId',
};

export const deviceParameters = {
  TEMPERATURE: 'temperature',
  HUMIDITY: 'humidity',
  LUMINOSITY: 'luminosity',
  PRESSURE: 'pressure',
  SOIL_MOISTURE: 'soilMoisture',
  AIR_HUMIDITY: 'airHumidity',
};

export const unitTypes = {
  LUX: 'Lux',
  PA: 'Pa',
  PERCENT: '%',
  CELSIUS: '\u00B0C',
};

export const deviceTypes = {
  SENSOR: 'sensor',
  SWITCH: 'switch',
};

export const timeSeconds = {
  QUARTER_HOUR: 3600 / 4,
  ONE_DAY: 86400,
  ONE_WEEK: 604800,
  ONE_MONTH: 2592000,
};

export const powerStatus = {
  BATTERY_EMPTY: 'BATTERY_EMPTY',
  BATTERY_LOW: 'BATTERY_LOW',
  BATTERY_MEDIUM: 'BATTERY_MEDIUM',
  BATTERY_HIGH: 'BATTERY_HIGH',
  POWER_NO_INFO: 'POWER_NO_INFO',
  POWER_CABLE: 'POWER_CABLE',
};

export const RESOLUTIONS = {
  RAW: 'RAW',
  TEN_MIN: 'TEN_MIN',
  HOURLY: 'HOURLY',
  DAILY: 'DAILY',
};
