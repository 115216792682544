import styled from 'styled-components';
import { Tabs } from 'antd';
import variables from 'styles/variables.module.scss';

const StyledTabs = styled(Tabs)`
  &&&.ant-tabs {
    overviewContentflex: auto;
    display: flex;
  }
   
  .ant-tabs-content{
    background-color:#fff;

  }
  &&& .ant-tabs-tabpane,
  .ant-tabs-content,
  .ant-tabs {
    height: 100%;
  }
  &.ant-tabs {
    .ant-tabs-content {
      border-width: 1px;
      border-style: solid;
      border-color: ${variables.borderColor}; 
      border-top: 1px solid #fff; 
    }
    .ant-tabs-nav { 
      margin: 0;
      .ant-tabs-tab {
        border-width: 1px;
        border-style: solid;
        border-color: ${variables.borderColor};  
        background-color:${variables.headerBgColor};  
        &.ant-tabs-tab-active {
          border-bottom: 1px solid #fff;
          background-color: #fff;
        }
        &.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: ${variables.primaryColor};
        }
        &:active {
          color: ${variables.primaryColorHover};
        }
        &:hover {
          color: ${variables.primaryColorHover}};
        }
        .ant-tabs-tab-btn {
          &:active {
            color: ${variables.primaryColorHover};
          }
          &:hover {
            color: ${variables.primaryColorHover}};
          }
        }
      }
    }
  }
`;

interface CustomTabsProps {
  tabOption: string;
  onChangeTab: (key: string) => void;
  tabItems: { label: string; key: string; children: JSX.Element }[];
}

const CustomTabs = ({ tabOption, onChangeTab, tabItems }: CustomTabsProps) => (
  <StyledTabs
    activeKey={tabOption}
    onChange={onChangeTab}
    type='card'
    items={tabItems.map((currentTab, i) => {
      return {
        label: currentTab.label,
        key: currentTab.key,
        children: currentTab.children,
      };
    })}
  />
);

export default CustomTabs;
