import styled from 'styled-components';
import { Select } from 'antd';
import variables from '../../../styles/variables.module.scss';

const { Option } = Select;

const StyledSelect = styled(Select)`
  width: 200px;
  margin-left: 0;
  margin-top: 0;
  padding: 0;

  &&&:not(.ant-select-disabled):not(.ant-select-customize-input):not(
      .ant-pagination-size-changer
    ):hover {
    .ant-select-selector {
      border-color: ${variables.primaryColor};
    }
  }

  &&&:not(.ant-select-disabled).ant-select-focused .ant-select-selector {
    border-color: ${variables.primaryColor};
    box-shadow: none;
  }
`;

const DropdownWrapper = styled.div`
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba(5, 161, 85, 0.15);
  }
`;

interface DropdownProps {
  id: any;
  options: any[];
  defaultValue: any;
  value: any;
  onChange: any;
  rest?: any;
}

const Dropdown = ({ id, options, defaultValue, value, onChange, ...rest }: DropdownProps) => (
  <StyledSelect
    id={id}
    value={value}
    defaultValue={defaultValue}
    onChange={onChange}
    dropdownRender={(menu) => <DropdownWrapper>{menu}</DropdownWrapper>}
    {...rest}
  >
    {options.map((option) => (
      <Option key={option.value} value={option.value}>
        {option.label}
      </Option>
    ))}
  </StyledSelect>
);

export default Dropdown;
