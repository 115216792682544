import { dateRangeOptions } from 'config/constant';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Custom hook to manage URL parameters in the application.

 * @returns {[urlParams: object, updateUrlParams: function]} - Returns an array with the current URL parameters and a function to update them.

 */

const useUrlParams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [urlParams, setUrlParams] = useState(() => {
    const searchParams = new URLSearchParams(location.search);
    return {
      tab: searchParams.get('tab') || '1',
      timeOption: searchParams.get('timeOption') || dateRangeOptions.lastDayOptionId,
      startDate: searchParams.get('startDate') || undefined,
      endDate: searchParams.get('endDate') || undefined,
      zoomDomainMin: searchParams.get('zoomDomainMin') || undefined,
      zoomDomainMax: searchParams.get('zoomDomainMax') || undefined,
    };
  });

  const updateUrlParams = useCallback(
    (params: Record<string, any>) => {
      const searchParams = new URLSearchParams(location.search);
      Object.entries(params).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
      });
      const newPath = `${location.pathname}?${searchParams.toString()}`;
      navigate(newPath, { replace: true });
    },
    [navigate, location]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const currentParams = {
      tab: searchParams.get('tab') || '1',
      timeOption: searchParams.get('timeOption') || dateRangeOptions.lastDayOptionId,
      startDate: searchParams.get('startDate') || undefined,
      endDate: searchParams.get('endDate') || undefined,
      zoomDomainMin: searchParams.get('zoomDomainMin') || undefined,
      zoomDomainMax: searchParams.get('zoomDomainMax') || undefined,
    };
    if (JSON.stringify(urlParams) !== JSON.stringify(currentParams)) {
      setUrlParams(currentParams);
    }
  }, [location.search, urlParams]);

  return [urlParams, updateUrlParams] as const;
};

export default useUrlParams;
