import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './Sidebar.module.scss';
import { StyledMenu, StyledSider } from './Sidebar.styles';

interface MenuItem {
  key: string;
  label: string;
  icon: JSX.Element;
}

export interface SidebarProps {
  collapsed: boolean;
  toggleCollapsed: () => void;
  menuItems: MenuItem[];
  onItemSelect: (item: any) => void;
  selectedKey: string | null;
}

const Sidebar: React.FC<SidebarProps> = ({
  collapsed,
  toggleCollapsed,
  menuItems,
  onItemSelect,
  selectedKey,
}) => {
  const items = menuItems.map((item) => ({
    key: item.key,
    icon: item.icon,
    label: item.label,
    onClick: () => onItemSelect(item),
  }));

  return (
    <StyledSider trigger={null} collapsible collapsed={collapsed}>
      <div className={styles.sideButton}>
        {collapsed ? (
          <MenuUnfoldOutlined className='trigger' onClick={toggleCollapsed} />
        ) : (
          <MenuFoldOutlined className='trigger' onClick={toggleCollapsed} />
        )}
      </div>
      {selectedKey && (
        <StyledMenu theme='dark' mode='inline' selectedKeys={[selectedKey]} items={items} />
      )}
      {!selectedKey && <StyledMenu theme='dark' mode='inline' items={items} />}
    </StyledSider>
  );
};

export default Sidebar;
