import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Alert } from 'antd';
import { setUser } from 'store/slices/auth.slice';
import { setTenants, setSelectedTenant } from 'store/slices/tenants.slice';
import styles from './Login.module.scss';
import Button from 'components/common/Button/Button';
import Input from 'components/common/Input/Input';
import { StyledFormItem } from 'components/common/Input/Input.styles';
import { useLoginMutation } from 'services/auth.service';

const LoginPage: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [login] = useLoginMutation();

  const onLogin = async () => {
    setError(null);

    try {
      const response = await login({ username, password }).unwrap();
      localStorage.setItem('user', JSON.stringify(response.user));
      localStorage.setItem('access_token', response.access_token);
      localStorage.setItem('refresh_token', response.refresh_token);
      dispatch(setUser(response.user));
      dispatch(setTenants(response.user.organizations));
      dispatch(setSelectedTenant(response.user.organizations[0]));

      if (response.user.firstLogin) {
        navigate('/reset-password');
      } else {
        navigate('/');
      }
    } catch (error: any) {
      localStorage.clear();
      if (error && error.status === 401) {
        console.error(error.data.blocked);
        if (error.data.message === 'Too many failed login attempts. Try again later.') {
          setError('Ip is blocked.');
        } else {
          setError('Incorrect username or password.');
        }
      } else {
        console.error(error);
        setError('Error logging in');
      }
    }
  };

  const onLoginFailed = (errorInfo: string) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={styles.loginContainer}>
      <div className={styles.loginForm}>
        <h1 className={styles.title}>Tato</h1>
        <p className={styles.subtitle}>Nurturing plants with smart insights</p>
        <Form
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onLogin}
          onFinishFailed={() => onLoginFailed}
        >
          <StyledFormItem
            name='username'
            rules={[{ required: true, message: 'Please input your username!' }]}
            className='custom-form-item'
          >
            <Input placeholder='Username' onChange={(e) => setUsername(e.target.value)} />
          </StyledFormItem>

          <StyledFormItem
            name='password'
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder='Password' onChange={(e) => setPassword(e.target.value)} />
          </StyledFormItem>

          <StyledFormItem>
            <Button primary fullWidth htmlType='submit'>
              Login
            </Button>
          </StyledFormItem>
          {error && <Alert message={error} type='error' showIcon />}
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;
