import React from 'react';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import StyledTable from './Table.styles';

interface DataTableProps {
  data: any;
  columns: (ColumnGroupType<any> | ColumnType<any>)[];
  onRowClick?: (record: any, rowIndex: number) => void;
}

const DataTable: React.FC<DataTableProps> = ({ data, columns, onRowClick, ...restProps }) => (
  <StyledTable
    columns={columns}
    dataSource={data}
    pagination={false}
    rowHoverable
    onRow={(record, rowIndex) => {
      return {
        onClick: (event) => {
          if (onRowClick) {
            onRowClick(record, rowIndex ?? 0);
          }
        },
      };
    }}
    {...restProps}
  />
);

export default DataTable;
